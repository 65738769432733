module controllers {
    export module master {
        interface IProductColourDirectiveScope extends ng.IScope {
        }

        export class productColourDirectiveCtrl {
            static $inject = ["$scope", "statusService", "generalService", "$uibModal", "$q"];
            
            constructor(private $scope: IProductColourDirectiveScope, private statusService: services.master.statusService,
                public generalService: interfaces.applicationcore.IGeneralService, private $uibModal: ng.ui.bootstrap.IModalService,
                private $q: ng.IQService) {
            }
        };
    }

    angular.module("app").controller("productColourDirectiveCtrl", controllers.master.productColourDirectiveCtrl);
}